import React from 'react';
import {
  faBoxArchive,
  faCheck,
  faEdit,
  faInboxOut,
  faPresentation,
  faTrash,
  faXmark
} from '@fortawesome/pro-regular-svg-icons';
import {
  duplicateProject,
  cancelProject,
  finishProject,
  deleteAction,
  bar,
  applyTemplatetoProject,
  reactivateProject,
  unarchiveOpportunity,
  archiveOpportunity,
  winOpportunity
} from './_dropdown';

const projectList = row =>
  row?.version !== 'V2'
    ? [
        {
          ...deleteAction,
          label: 'Excluir projeto',
          iconProps: {
            icon: faTrash
          },
          modalParams: {
            title: `Atenção, seu projeto será excluído!`,
            text: (
              <p>
                Ao confirmar essa ação, o projeto será <b>EXCLUÍDO</b> por completo.
              </p>
            )
          }
        }
      ]
    : [
        {
          label: 'Editar',
          id: 'edit-project',
          redirectTo: `/profissional/projetos/editar/${row.id}`,
          hasId: true,
          iconProps: {
            icon: faEdit
          }
        },
        duplicateProject,
        cancelProject,
        finishProject,
        {
          ...deleteAction,
          label: 'Excluir projeto',
          iconProps: {
            icon: faTrash
          },
          modalParams: {
            title: `Atenção, seu projeto será excluído!`,
            text: (
              <p>
                Ao confirmar essa ação, o projeto será <b>EXCLUÍDO</b> por completo.
              </p>
            )
          }
        },
        bar,
        applyTemplatetoProject,
        {
          id: 'apply-template',
          label: 'Aplicar um template',
          modal: 'templatesModal',
          verb: 'post',
          values: { type: 2 },
          iconProps: {
            icon: faPresentation
          }
        }
      ];
const projectItemList = (id, userType, idStep, fixedRefurbishNewStatus) => [
  {
    id: 'edit-project',
    label: 'Editar',
    redirectTo: `/${userType}/projetos/editar/${id}`,
    hasId: true,
    iconProps: {
      icon: faEdit
    }
  },
  duplicateProject,
  idStep === fixedRefurbishNewStatus.cancelled.id || idStep === fixedRefurbishNewStatus.finished.id
    ? reactivateProject
    : {
        ...cancelProject,
        displayToast: 'Seu projeto foi cancelado. Para vê-lo na lista de projetos, utilize os filtros disponíveis'
      },
  ...(idStep !== fixedRefurbishNewStatus.finished.id
    ? [
        {
          ...finishProject,
          displayToast: 'Seu projeto foi finalizado. Para vê-lo na lista de projetos, utilize os filtros disponíveis'
        }
      ]
    : []),
  {
    ...deleteAction,
    modalParams: {
      title: `Atenção, seu projeto será excluído!`,
      text: (
        <p>
          Ao confirmar essa ação, o projeto será <b>EXCLUÍDO</b> por completo.
        </p>
      ),
      redirectTo: '/profissional/projetos'
    },
    label: 'Excluir projeto',
    iconProps: {
      icon: faTrash
    },
    displayToast: 'Operação realizada com sucesso!'
  },
  bar,
  applyTemplatetoProject,
  {
    id: 'apply-template',
    label: 'Aplicar um template',
    modal: 'templatesModal',
    verb: 'post',
    values: { type: 2 },
    iconProps: {
      icon: faPresentation
    }
  }
];
const opportunityItemList = (id, userType, idStatus, refurbishStatus, handleSubmit) => [
  {
    id: 'edit-opportunity',
    label: 'Editar',
    redirectTo: `/${userType}/oportunidades/editar/${id}`,
    hasId: true,
    iconProps: {
      icon: faEdit
    }
  },
  [
    {
      ...duplicateProject,
      id: 'duplicate-opportunity',
      label: 'Duplicar',
      modalParams: {
        ...duplicateProject.modalParams,
        title: 'Nova Oportunidade',
        textHeader: `Você pode duplicar uma oportunidade com seus itens no orçamento,
        estrutura de pastas (sem os arquivos) e também suas anotações`,
        text: `Escolha o que deseja duplicar desta oportunidade.`,
        successText: 'Oportunidade duplicada com sucesso',
        source: 'Oportunidade',
        isOpportunity: true
      }
    }
  ],
  idStatus === refurbishStatus.archived.id || idStatus === refurbishStatus.winner.id
    ? {
        ...unarchiveOpportunity,
        iconProps: {
          icon: faInboxOut
        },
        displayToast: 'Sua oportunidade foi reativada.'
      }
    : {
        ...archiveOpportunity,
        iconProps: {
          icon: faBoxArchive
        },
        displayToast: 'Sua oportunidade foi arquivada.'
      },
  ...(idStatus !== refurbishStatus.winner.id
    ? [
        {
          ...winOpportunity,
          modalParams: {
            ...winOpportunity.modalParams,
            onSubmitApply: value => handleSubmit(winOpportunity.modalParams.idStatus, value, 'opportunity-gain')
          },
          iconProps: {
            icon: faCheck
          }
        }
      ]
    : []),
  {
    ...deleteAction,
    modalParams: {
      title: `Atenção, sua oportunidade será excluída!`,
      text: (
        <p>
          Ao confirmar essa ação, a oportunidade será <b>EXCLUÍDA</b> por completo.
        </p>
      ),
      redirectTo: '/profissional/oportunidades'
    },
    label: 'Excluir',
    iconProps: {
      icon: faTrash
    },
    displayToast: 'Operação realizada com sucesso!'
  },
  bar,
  [
    {
      ...applyTemplatetoProject,
      modalParams: {
        ...applyTemplatetoProject.modalParams,
        textHeader: `Você pode criar um template usando seus itens no orçamento,
        estrutura de pastas (sem os arquivos) e também suas anotações da oportunidade.
        Assim, você terá sempre disponível esse template para utilizar em futuros projetos ou oportunidades,
        sem precisar criar do zero.`,
        isOpportunity: true
      }
    }
  ],
  {
    id: 'apply-template',
    label: 'Aplicar um template',
    modal: 'templatesModal',
    verb: 'post',
    values: { type: 2 },
    iconProps: {
      icon: faPresentation
    }
  }
];
const projectListEnd = [
  duplicateProject,
  reactivateProject,
  {
    ...deleteAction,
    label: 'Excluir projeto',
    iconProps: {
      icon: faTrash
    },
    modalParams: {
      title: `Atenção, seu projeto será excluído!`,
      text: (
        <p>
          Ao confirmar essa ação, o projeto será <b>EXCLUÍDO</b> por completo.
        </p>
      ),
      redirectTo: '/profissional/projetos'
    }
  },
  applyTemplatetoProject
];
const projectKanban = row =>
  row?.version !== 'V2'
    ? [
        {
          ...deleteAction,
          label: 'Excluir projeto',
          iconProps: {
            icon: faTrash
          },
          modalParams: {
            title: `Atenção, seu projeto será excluído!`,
            text: (
              <p>
                Ao confirmar essa ação, o projeto será <b>EXCLUÍDO</b> por completo.
              </p>
            )
          }
        }
      ]
    : [
        finishProject,
        duplicateProject,
        {
          ...cancelProject,
          displayToast: 'Seu projeto foi cancelado. Para vê-lo na lista de projetos, utilize os filtros disponíveis'
        },
        {
          label: 'Alterar status',
          id: 'change-refurbish-status',
          button: 'editStatus'
        },
        {
          label: 'Remover do status',
          verb: 'put',
          iconProps: {
            icon: faXmark
          },
          pathOptions: '',
          values: { idStep: null }
        },
        applyTemplatetoProject
      ];
const opportunityKanban = [winOpportunity, archiveOpportunity, deleteAction];
const listOpportunityItemArchived = [unarchiveOpportunity, deleteAction];
const listOpportunityItem = [archiveOpportunity, deleteAction];

export {
  projectList,
  projectItemList,
  opportunityItemList,
  projectListEnd,
  projectKanban,
  opportunityKanban,
  listOpportunityItemArchived,
  listOpportunityItem
};
