/* A filter contains:
 a filterName: (name, status, isActive, date}
 a label : (Nome, Ativo)
 a extra: content to render ahead the label
 a type: text, select or date
 if (type === text) nothing needs to be provided
 if (type === select) it should inform what type of data the
 select searchable  will search:
   - if property model is provided it will search at  _SearchableMode
   - if property dataType is provided it will search at  _SearchableDataType
   - if property EnumType is provided it will search at  _SearchableENUM
   - if property custom and location is provided it will import a specific searchable
    file and it will provide the data to it.
 */
import React from 'react';
import { StyledColor } from '../../../components/Dropdown/ColorPickerDropDown.styled';

const simpleDescription = {
  name: 'description',
  filterName: 'Descrição',
  type: 'text'
};

const name = {
  name: 'name',
  filterName: 'Nome',
  type: 'text',
  filterFunc: value => ({ name: { like: `%${value?.like || value}%` } }),
  filterPath: 'like'
};

const code = {
  name: 'code',
  filterName: 'Código',
  type: 'text',
  filterFunc: value => ({ code: { like: `%${value?.like || value}%` } }),
  filterPath: 'like'
};

const type = { name: 'type', filterName: 'Tipo', type: 'select', dataType: 'supplierType' };
const idCostCenter = {
  name: 'idCostCenter',
  filterName: 'Categoria',
  type: 'select',
  options: {
    model: 'costCenter',
    modelOptions: { onlyMine: true, where: { isActive: true }, order: ['name'] }
  }
};
const nameOrEmail = {
  name: 'or',
  filterName: 'Nome ou Email',
  type: 'text',
  filterFunc: value => ({
    or: [{ name: { like: `%${value}%` } }, { email: { like: `%${value}%` } }]
  })
};

const nameOrEmailOrCompany = {
  name: 'or',
  filterName: 'Nome ou Email ou Cód Empresa',
  type: 'text',
  filterFunc: value => {
    const _filter = { or: [{ name: { like: `%${value}%` } }, { email: { like: `%${value}%` } }] };
    if (!Number.isNaN(parseInt(value, 10))) _filter.or.push({ idCompany: parseInt(value, 10) });
    return _filter;
  }
};

const codeOrName = {
  name: 'or',
  filterName: 'Código ou nome',
  type: 'text',
  filterFunc: value => ({
    or: [{ name: { like: `%${value}%` } }, { code: { like: `%${value}%` } }]
  })
};
const isActive = { filterName: 'Status', name: 'isActive', type: 'select', dataType: 'activeStatus' };
const itemTypes = { name: 'type', filterName: 'Tipo', type: 'select', dataType: 'refurbishItemTypes' };
const idStatus = {
  name: 'idStatus',
  filterName: 'Status',
  type: 'select'
};
const idUser = {
  name: 'idUser',
  filterName: 'Cliente',
  type: 'select',
  options: { model: 'user', modelOptions: { where: { isActive: true }, order: ['name'] } }
};
const _idRefurbish = {
  name: 'idRefurbish',
  filterName: 'Projeto',
  type: 'select',
  options: {
    model: 'refurbish',
    modelOptions: {
      include: [
        {
          model: 'RefurbishStatus',
          as: 'status',
          where: { idStage: 2 }
        }
      ],
      order: ['name']
    }
  }
};
const idProvider = {
  name: `idCompany`,
  filterName: 'Empresa',
  type: 'select',
  options: { model: 'company', modelOptions: { where: { isActive: true }, order: ['name'] } }
};

const idResponsible = {
  filterName: 'Responsável',
  name: 'idResponsible',
  options: {
    model: 'user',
    modelOptions: { where: { isActive: true, userType: 'provider' }, order: ['name'] }
  }
};

const object = { name: 'object', filterName: 'Tipo', type: 'select', dataType: 'pipedriveType' };

const templateOwnerType = {
  filterName: 'Tipo',
  name: 'templateOwnerType',
  type: 'select',
  dataType: 'templateOwnerType'
};

const opportunityStatus = {
  filterName: 'Status',
  name: 'idStatus',
  type: 'select',
  dataType: 'opportunityStatus'
};

const newStatus = {
  filterName: 'Status',
  name: 'idStep',
  options: {
    model: 'step',
    modelOptions: { where: { or: [{ fixed: true }, { idCompany: { ne: null } }] }, order: ['name'] }
  },
  extra: ({ item }) => <StyledColor key={item?.color} role="presentation" color={item?.color} />
};

const projectResponsible = {
  filterName: 'Responsáveis no projeto',
  name: 'projectIdResponsible',
  options: {
    model: 'user',
    modelOptions: { where: { isActive: true, userType: 'provider' }, order: ['name'] }
  }
};

const priority = { name: 'priority', filterName: 'Prioridade', type: 'select', dataType: 'taskPriority' };

const financialInstallment = ({
  billType,
  refurbishFilter,
  isCashFLow,
  isCustomer,
  idCompany,
  idRefurbish,
  withoutPaymentType,
  isBankStatement,
  isReport
}) => [
  ...(!isCashFLow && !isBankStatement && !isReport
    ? [
        {
          filterName: 'Nota Fiscal',
          name: 'invoices',
          type: 'select',
          dataType: 'paymentInvoices',
          onlyOne: true
        }
      ]
    : []),
  ...(!isCashFLow
    ? [
        {
          filterName: 'Categoria',
          name: 'idFinancialCategory',
          options: {
            model: 'financialCategory',
            modelOptions: {
              onlyMine: true,
              where: {
                idCompany,
                isActive: true,
                billType,
                withoutChildren: true
              },
              order: [['name', 'asc']]
            }
          }
        }
      ]
    : []),
  ...(!refurbishFilter ? [_idRefurbish] : []),
  ...(!isCustomer && !isBankStatement
    ? [
        {
          filterName: 'Conta',
          name: 'idPaymentBankAccount',
          options: {
            model: 'bankAccount',
            modelOptions: {
              where: { idCompany, isFinancialEntry: true, onlyMine: true },
              order: [['name', 'asc']]
            }
          }
        }
      ]
    : []),
  {
    filterName: 'Centro de custo',
    name: 'idPaymentCostCenter',
    options: {
      model: 'paymentCostCenter',
      modelOptions: { where: { isActive: true, idCompany, idRefurbish }, order: [['name', 'asc']] }
    }
  },
  ...(!withoutPaymentType
    ? [
        {
          filterName: 'Pago com',
          name: 'idPaymentType',
          options: {
            model: 'paymentType',
            modelOptions: { where: { isActive: true }, order: [['name', 'asc']] },
            customLabel: opt => (opt.id === 0 ? 'Sem Forma de Pagamento' : opt.name)
          }
        }
      ]
    : [])
];

const filterForm = {
  common: [name, isActive],
  opportunity: [name, opportunityStatus, projectResponsible],
  opportunityProject: [
    newStatus,
    {
      filterName: 'Cliente',
      name: 'idCompanyCustomer',
      options: { model: 'companyCustomer', modelOptions: { order: ['name'] } }
    },
    projectResponsible
  ],
  generalSchedule: [
    newStatus,
    {
      filterName: 'Responsáveis nas tarefas',
      name: 'idResponsible',
      options: {
        model: 'user',
        modelOptions: { where: { isActive: true, userType: ['provider', 'guest'] }, order: ['name'] }
      }
    },
    projectResponsible
  ],
  generalPlanning: [
    {
      filterName: 'Responsáveis nas atividades',
      name: 'idResponsible',
      options: {
        model: 'user',
        modelOptions: { where: { isActive: true, userType: ['provider', 'guest'] }, order: ['name'] }
      }
    },
    projectResponsible
  ],
  payment: ({
    billType,
    hasInvoicePermission,
    hasSupplierPaymentPermission,
    refurbishFilter,
    isPayment,
    isCustomer,
    idCompany,
    idRefurbish,
    paymentStatuses
  }) => [
    ...financialInstallment({ billType, refurbishFilter, isCustomer, idCompany, idRefurbish }),
    ...(isPayment
      ? [
          {
            filterName: 'Status',
            name: 'idPaymentStatus',
            options: {
              model: 'paymentStatus',
              modelOptions: {
                where: {
                  isActive: true,
                  idCompany,
                  idRefurbish,
                  ...(isCustomer && { id: { notIn: [paymentStatuses.draft, paymentStatuses.cancelled] } })
                },
                order: [['name', 'asc']]
              }
            }
          }
        ]
      : []),
    ...(!isCustomer
      ? [
          {
            filterName: 'Cliente',
            name: 'idCompanyCustomer',
            options: { model: 'companyCustomer', modelOptions: { order: [['name', 'asc']] } }
          }
        ]
      : []),
    ...(hasSupplierPaymentPermission
      ? [
          {
            filterName: 'Fornecedor',
            name: 'idSupplier',
            options: {
              model: hasInvoicePermission || isCustomer ? 'supplier/with-my-business' : 'supplier',
              modelName: 'supplier',
              modelOptions: {
                onlyMine: true,
                where: { isActive: true, idCompany, idRefurbish },
                order: [['name', 'asc']]
              }
            }
          }
        ]
      : [])
  ],
  income: ({ billType, refurbishFilter }) => [
    ...financialInstallment({ billType, refurbishFilter }),
    {
      filterName: 'Cliente',
      name: 'idCompanyCustomer',
      options: { model: 'companyCustomer', modelOptions: { order: [['name', 'asc']] } }
    }
  ],
  expense: ({ billType, refurbishFilter }) => [
    ...financialInstallment({ billType, refurbishFilter }),
    {
      filterName: 'Fornecedor',
      name: 'idSupplier',
      options: { model: 'supplier', modelOptions: { onlyMine: true, order: [['name', 'asc']] } }
    }
  ],
  transfer: ({ idCompany }) => [
    {
      filterName: 'Conta',
      name: 'idPaymentBankAccount',
      options: {
        model: 'bankAccount',
        modelOptions: { where: { isActive: true, idCompany }, order: [['name', 'asc']] }
      }
    }
  ],
  '["expense","income","transfer","balance"]': ({ billType, refurbishFilter }) => [
    ...financialInstallment({ billType, refurbishFilter, isBankStatement: true }),
    {
      filterName: 'Cliente',
      name: 'idCompanyCustomer',
      options: { model: 'companyCustomer', modelOptions: { order: [['name', 'asc']] } }
    },
    {
      filterName: 'Fornecedor',
      name: 'idSupplier',
      options: { model: 'supplier', modelOptions: { onlyMine: true, order: [['name', 'asc']] } }
    },
    {
      filterName: 'Tipo',
      name: 'billType',
      type: 'select',
      dataType: 'paymentTypeValues'
    },
    {
      filterName: 'Conciliado',
      name: 'reconciled',
      type: 'select',
      dataType: 'installmentConciliation',
      onlyOne: true
    }
  ],
  resultsByInstallment: ({ billType, refurbishFilter, isCashFLow, isReport, withoutPaymentType }) => [
    ...financialInstallment({ billType, refurbishFilter, isCashFLow, isReport, withoutPaymentType }),
    {
      filterName: 'Cliente',
      name: 'idCompanyCustomer',
      options: { model: 'companyCustomer', modelOptions: { order: [['name', 'asc']] } }
    },
    {
      filterName: 'Fornecedor',
      name: 'idSupplier',
      options: {
        model: 'supplier',
        modelOptions: { onlyMine: true, where: { isActive: true }, order: [['name', 'asc']] }
      }
    }
  ],
  pipedrive: [object],
  companyCustomer: [nameOrEmail, isActive],
  supplier: [nameOrEmail, type, idCostCenter],
  userCustomer: [nameOrEmail, isActive],
  userOperator: [name, isActive],
  userProvider: [
    nameOrEmailOrCompany,
    { ...idStatus, options: { model: 'company-status', modelOptions: { where: { isActive: true }, order: ['name'] } } }
  ],
  usersProvider: [
    nameOrEmail,
    { ...idStatus, options: { model: 'company-status', modelOptions: { where: { isActive: true }, order: ['name'] } } }
  ],
  usersGuest: [nameOrEmail, isActive],
  work: [
    name,
    idProvider,
    idUser,
    {
      ...idStatus,
      options: { model: 'refurbishStatus', modelOptions: { where: { isActive: true }, order: ['name'] } }
    }
  ],
  product: [
    {
      name: 'brand',
      filterName: 'Marca',
      type: 'text',
      filterFunc: value => ({ brand: { like: `%${value?.like || value}%` } }),
      filterPath: 'like'
    },
    name,
    idCostCenter
  ],
  template: [
    name,
    {
      ...idProvider,
      options: { ...idProvider?.options, modelOptions: { ...idProvider.options.modelOptions, limit: 10 } }
    },
    templateOwnerType
  ],
  item: [
    codeOrName,
    {
      name: 'idRefurbishGroup',
      filterName: 'Grupo',
      type: 'select',
      options: {
        model: 'refurbishGroup',
        modelOptions: { order: [['name', 'asc']] }
      }
    },
    idCostCenter,
    {
      name: 'idSupplier',
      filterName: 'Fornecedor',
      type: 'select',
      options: {
        model: 'supplier',
        modelOptions: { onlyMine: true, order: [['name', 'asc']] }
      }
    }
  ],
  composition: [
    codeOrName,
    {
      name: 'idClass',
      filterName: 'Classe',
      type: 'select',
      options: {
        model: 'itemClass',
        modelOptions: { order: [['name', 'asc']] }
      }
    }
  ],
  installment: [simpleDescription],
  itemsExtraDrawer: [
    { name: 'price', filterName: 'Valor', type: 'select', dataType: 'price' },
    {
      name: 'brand',
      filterName: 'Marca',
      type: 'text',
      filterFunc: value => ({ brand: { like: `%${value?.like || value}%` } }),
      filterPath: 'like'
    },
    {
      name: 'idSupplier',
      filterName: 'Loja',
      type: 'select',
      options: {
        model: 'supplier',
        modelOptions: { onlyMine: true, order: [['name', 'asc']] }
      }
    },
    itemTypes
  ],
  itemsToComposition: (costCenterFilters, typeFilters, disableTypeFilter) => [
    {
      key: 'price',
      name: 'Preço',
      showScreen: true,
      type: 'range'
    },
    {
      items: costCenterFilters,
      key: 'costCenter',
      name: 'Categoria',
      showScreen: true,
      type: 'default'
    },
    ...(disableTypeFilter
      ? []
      : [
          {
            items: typeFilters,
            key: 'type',
            name: 'Tipo',
            showScreen: true,
            type: 'default'
          }
        ])
  ],
  shoppingCatalog: (typeFilters, levels, refurbishItemStatus, suppliers, onlyProducts) => [
    {
      name: 'Preço',
      showScreen: true,
      type: 'range'
    },
    ...(!onlyProducts
      ? [
          {
            items: typeFilters,
            name: 'Tipo',
            showScreen: true,
            type: 'default'
          }
        ]
      : []),
    {
      items: levels,
      name: 'Nível',
      showScreen: true,
      type: 'default'
    },
    {
      items: suppliers,
      name: 'Fornecedores',
      showScreen: true,
      type: 'default'
    },
    {
      items: refurbishItemStatus,
      name: 'Status',
      showScreen: true,
      type: 'default'
    }
  ],
  workReport: [code, { name: 'status', filterName: 'Status', type: 'select', dataType: 'workReportStatus' }],
  workReportGeneral: [
    code,
    { name: 'status', filterName: 'Status', type: 'select', dataType: 'workReportStatus' },
    _idRefurbish
  ],
  planning: [{ ...idResponsible, name: 'plIdResponsible' }],
  performedHours: ({ isRefurbish }) => [idResponsible, ...(!isRefurbish ? [_idRefurbish] : [])],
  bankAccount: [
    {
      filterName: 'Banco',
      name: 'idBankInstitution',
      options: {
        model: 'bankInstitution',
        modelOptions: { where: { isActive: true }, order: ['order'] }
      }
    }
  ],
  bankReconciliationImports: ({ idCompany }) => [
    {
      filterName: 'Conta',
      name: 'idBankAccount',
      options: {
        model: 'bankAccount',
        modelOptions: { where: { isActive: true, idCompany }, order: [['name', 'asc']] }
      }
    }
  ],
  purchase: ({ idRefurbish, isPurchase }) => [
    ...(!idRefurbish ? [_idRefurbish] : []),
    idResponsible,
    priority,
    ...(!isPurchase
      ? [
          {
            name: 'idSupplier',
            filterName: 'Fornecedor',
            type: 'select',
            options: {
              model: 'supplier',
              modelOptions: { onlyMine: true, order: [['name', 'asc']] }
            }
          }
        ]
      : [])
  ],
  'order/overviewReport': [
    {
      name: 'type',
      filterName: 'Tipo',
      type: 'select',
      dataType: 'itemTypesWithoutLevel'
    },
    {
      name: 'averageCost',
      filterName: 'Custo médio',
      type: 'select',
      dataType: 'purchaseEstimativeCostOptions'
    },
    {
      name: 'totalCost',
      filterName: 'Custo total',
      type: 'select',
      dataType: 'purchaseEstimativeCostOptions'
    },
    {
      name: 'budgetStatus',
      filterName: 'Status no orçamento',
      type: 'select',
      dataType: 'refurbishItemStatus'
    }
  ],
  quote: [
    {
      name: 'idSupplier',
      filterName: 'Fornecedor',
      type: 'select',
      options: {
        model: 'supplier',
        modelOptions: { onlyMine: true, order: [['name', 'asc']] }
      }
    },
    idResponsible,
    priority
  ],
  quoteSuppliers: [idCostCenter],
  selectQuoteSuppliers: [idCostCenter]
};

export { filterForm };
