import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { faPlus, faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import { v4 as uuidV4 } from 'uuid';

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { faSquareList } from '@fortawesome/pro-duotone-svg-icons';
import { columns as purchaseColumns } from '../../lib/mapping/TableOrList/purchaseColumns';

import { store } from '../../lib/config/redux-store';
import PurchaseBulkActions from '../BulkActions/PurchaseBulkActions';

import useCRUD from '../../_Hooks/useCRUD';
import { colors, Div, spaces } from '../../styles/style';
import CatalogDrawer from '../Drawer/CatalogDrawer';
import Button from '../Button/Button';
import formatNumber from '../../lib/helpers/formatNumber';
import { Error } from '../Text/Text';
import {
  formatAppropriation,
  generateChildrenList,
  updateListOnDelete,
  updateListOnEdit
} from '../../lib/helpers/order';
import useViewport from '../../_Hooks/useViewport';
import TableOrListV2 from '../List/TableOrListV2';
import { Context } from '../../contexts/GeneralContext';
import AddPurchaseItemForm from '../Form/AddPurchaseItemForm';
import NoContent from '../NoContent/NoContent';
import PurchaseFooter from '../MobileFooter/PurchaseFooter';

const PurchaseTable = ({
  list,
  setList,
  idRefurbish,
  parentColumnName = 'purchaseItems',
  childrenColumnName = 'purchaseItemLevels',
  readOnly,
  itemObjectsMap,
  itemSettersMap,
  height = '450px',
  paymentView,
  limitedTable,
  isCreatePayment,
  isSupplierView,
  type = 'purchase',
  priceWithBdi = false,
  refreshTable,
  invalidPaymentItems,
  setInvalidPaymentItems = f => f
}) => {
  const { setup } = store.getState();
  const { refurbishItemType = {} } = setup.enums;
  const { user } = useSelector(state => state.authReducer) || {};
  const { createdItems, createdLevels } = itemObjectsMap;
  const history = useHistory();
  const {
    setCreatedItems,
    setUpdatedItems,
    setDeletedItems,
    setCreatedLevels,
    setUpdatedLevels,
    setDeletedLevels
  } = itemSettersMap;

  const [expandItem, setExpandItem] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [itemAppropriationMap, setItemAppropriationMap] = useState({});
  const [itemsOnListMap, setItemsOnListMap] = useState({});
  const { isMobile } = useViewport(window.innerWidth);
  const _isMobile = isMobile();

  const [showCatalogDrawer, setShowCatalogDrawer] = useState(false);
  const [addItemType, setAddItemType] = useState(null);
  const [reload, setReload] = useState(false);

  const { idCompany } = user || {};

  const typeMap = {
    purchase: { noContentText: 'na Solicitação de Compra', title: 'solicitação' },
    order: { noContentText: 'na Ordem de Compra', title: 'ordem' },
    quote: { noContentText: 'no pedido de Cotação enviado para os fornecedores', title: 'cotação' }
  };

  const noContent = paymentView
    ? 'Busque ou crie novos produtos e serviços para inserir no lançamento financeiro'
    : `Busque ou crie novos itens para inserir ${typeMap[type].noContentText}`;

  const renderNoContent = !_isMobile && list?.length === 0 && !addItemType && !showCatalogDrawer;

  const { list: levelsList, handleGet: getLevelList } = useCRUD({
    model: 'refurbishItems',
    immediatelyLoadData: false
  });

  const rowId = rec => (rec?.type === refurbishItemType?.parent ? `level-${rec.id}` : rec.id);

  useEffect(() => {
    if (!idCompany || !idRefurbish) return;
    const newItemAppropriationMap = {};
    const newItemsOnListMap = {};

    list?.forEach(i => {
      const childrenListObj = (i?.[childrenColumnName] || []).reduce((acc, opt) => {
        const newData = opt;
        newData.quantity = Number(opt?.quantity) > Number(i.quantity) ? i.quantity : opt.quantity;
        acc[opt?.refurbishItem?.id] = [...(acc[opt?.refurbishItem?.id] || []), newData];
        return acc;
      }, {});

      newItemAppropriationMap[i.id] = {
        children: childrenListObj,
        data: i
      };
      const parentKey = i.idItem || `ri-${i.idRefurbishItem}`;
      newItemsOnListMap[parentKey] = true;
      i?.[childrenColumnName]?.forEach(child => {
        newItemsOnListMap[`${parentKey}-${child?.refurbishItem?.id}`] = true;
      });
    });
    setItemAppropriationMap(newItemAppropriationMap);
    setItemsOnListMap(newItemsOnListMap);
  }, [list, idRefurbish, idCompany]);

  useEffect(() => {
    // useEffect feito para resolver issue https://github.com/crawler-django/virtuallist-antd/issues/81
    setReload(false);
  }, [reload]);

  useEffect(() => {
    if (!idRefurbish || readOnly || !childrenColumnName) return;
    getLevelList({
      refetchOptions: {
        where: {
          idCompany,
          type: refurbishItemType?.parent,
          idRefurbish,
          idParent: null
        },
        order: [['order'], ['id']]
      }
    });
  }, [idRefurbish, childrenColumnName, readOnly]);

  const onItemClick = _type => {
    if (addItemType) return;
    setAddItemType(_type);
  };

  const disableAddButtons = !idRefurbish && !paymentView;

  const AddItemsButtonsRow = useMemo(() => {
    return (
      <Div
        padding={_isMobile ? `${spaces.space1} 0 0 0` : `0 0 ${renderNoContent ? spaces.space5 : spaces.space2}`}
        justify={list?.length === 0 && !addItemType && 'center'}
        width="100%"
      >
        {!readOnly && (
          <>
            {idRefurbish ? (
              <Button id="purchase-request-search-item" text type="primary" onClick={() => setShowCatalogDrawer(true)}>
                <FontAwesomeIcon icon={faSearch} />
                Buscar item
              </Button>
            ) : (
              <Div height={spaces.space4} />
            )}
            <>
              <Button
                disabled={disableAddButtons}
                id="add-product-purchase-item"
                text
                type="primary"
                onClick={() => onItemClick(refurbishItemType.product)}
              >
                <FontAwesomeIcon icon={faPlus} />
                Produto
              </Button>
              <Button
                disabled={disableAddButtons}
                id="add-service-purchase-item"
                text
                type="primary"
                onClick={() => onItemClick(refurbishItemType.labor)}
              >
                <FontAwesomeIcon icon={faPlus} />
                Serviço
              </Button>
            </>
          </>
        )}
      </Div>
    );
  }, [setShowCatalogDrawer, idRefurbish, readOnly, list?.length, paymentView, addItemType]);

  const addAppropriation = (idParent, appropriationList) => {
    if (!idParent) return;
    if (!appropriationList?.length) {
      toast.error('Todos os níveis do orçamento já foram apropriados nesse item');
      return;
    }

    setExpandItem(idParent);

    const newAppropriationId = uuidV4();

    setList(prev => {
      return prev.map(_item => {
        if (_item.id === idParent) {
          const newAppropriation = {
            idUnit: _item.idUnit,
            units: _item.units,
            price: _item.price || 0,
            type: refurbishItemType?.parent,
            id: newAppropriationId,
            idParent: _item.id,
            idRefurbishItem: appropriationList?.[0]?.id,
            quantity: 0,
            refurbishItem: {
              name: appropriationList?.[0]?.name,
              id: appropriationList?.[0]?.id
            }
          };
          return {
            ..._item,
            [childrenColumnName]: _item?.[childrenColumnName]
              ? [..._item[childrenColumnName], newAppropriation]
              : [newAppropriation]
          };
        }

        return _item;
      });
    });
    if (!createdItems[idParent]) {
      setCreatedLevels(prev => ({ ...prev, [newAppropriationId]: true }));
    }
  };

  const editRow = ({ value, key, index, object, objectKey, row, multipleEdit }) => {
    setInvalidPaymentItems('');
    const _type = row?.type || row?.refurbishItem?.type;
    if (!createdItems[row?.id] && !createdLevels[row?.id] && (!row?.idParent || !createdItems[row?.idParent])) {
      if (_type === refurbishItemType?.parent) {
        setUpdatedLevels(prev => ({ ...prev, [row?.id]: true }));
      } else {
        setUpdatedItems(prev => ({ ...prev, [row?.id]: true }));
        setUpdatedLevels(prev => {
          if (!list?.length || !list?.[index]?.[childrenColumnName]?.length) {
            return prev;
          }

          const groupedListObj = (list?.[index]?.[childrenColumnName] || []).reduce((acc, opt) => {
            if (createdLevels[opt?.id]) return acc;
            acc[opt?.id] = [...(acc[opt?.id] || []), opt];
            return acc;
          }, {});

          return { ...prev, ...groupedListObj };
        });
      }
    }

    setList(prev =>
      updateListOnEdit({
        originalList: prev,
        type: _type,
        value,
        row,
        multipleEdit,
        childrenColumnName,
        index,
        key,
        object,
        objectKey
      })
    );
  };

  const deleteRow = (_id, idParent) => {
    if (!_id) return;

    if (!createdItems[_id] && !createdLevels[_id] && (!idParent || !createdItems[idParent])) {
      if (idParent) {
        setDeletedLevels(prev => ({ ...prev, [_id]: true }));
      } else {
        setDeletedItems(prev => ({ ...prev, [_id]: true }));
      }
    }

    if (createdItems[_id] || createdLevels[_id]) {
      if (idParent) {
        setDeletedLevels(prev => ({ ...prev, [_id]: undefined }));
        setCreatedLevels(prev => ({ ...prev, [_id]: undefined }));
      } else {
        setDeletedItems(prev => ({ ...prev, [_id]: undefined }));
        setCreatedItems(prev => ({ ...prev, [_id]: undefined }));
      }
    }

    setList(prev => updateListOnDelete({ originalList: prev, idParent, childrenColumnName, id: _id }));
  };

  const linkRow = ({ libraryItem, index }) => {
    setList(prev => {
      return prev.map((_item, i) => {
        if (index === i) {
          return {
            ..._item,
            idItem: libraryItem?.id,
            code: libraryItem?.code,
            name: libraryItem?.name,
            units: { id: libraryItem?.idUnit, name: libraryItem?.units?.name },
            idUnit: libraryItem?.idUnit,
            price: formatNumber(libraryItem?.price) || 0,
            type: libraryItem?.type
          };
        }
        return _item;
      });
    });
    setShowCatalogDrawer(false);
  };

  const bulkEditItems = ({ ids, value, key }) => {
    const itemsChanged = {};
    const idsMap = ids.reduce((acc, cur) => ({ ...acc, [cur]: true }), {});

    setList(prev => {
      return prev.map(_item => {
        if (idsMap[_item.id]) {
          if (
            !createdItems[_item?.id] &&
            !createdLevels[_item?.id] &&
            (!_item?.idParent || !createdItems[_item?.idParent])
          ) {
            itemsChanged[_item.id] = true;
          }
          return {
            ..._item,
            [key]: value
          };
        }

        return _item;
      });
    });
    setUpdatedItems(prev => ({ ...prev, ...itemsChanged }));
  };

  const bulkDeleteItems = ids => {
    const itemsDeleted = {};
    const levelsDeleted = {};
    const idsMap = {};

    const createdItemsToDelete = {};
    const createdLevelsToDelete = {};

    ids.forEach(cur => {
      const formatedId = typeof cur === 'string' ? cur.replace('level-', '') : cur;
      if (createdLevels[formatedId]) {
        createdLevelsToDelete[formatedId] = undefined;
      }
      if (createdItems[formatedId]) {
        createdItemsToDelete[formatedId] = undefined;
      }
      idsMap[formatedId] = true;
    });

    setList(prev => {
      return prev.reduce((result, _item) => {
        if (idsMap[_item.id]) {
          itemsDeleted[_item.id] = true;
          return result;
        }

        result.push({
          ..._item,
          [childrenColumnName]: _item[childrenColumnName]?.filter(child => {
            const isDeleted = idsMap[child.id];
            if (isDeleted && Number(child.id)) levelsDeleted[child.id] = true;
            return !isDeleted;
          })
        });

        return result;
      }, []);
    });

    setDeletedItems(prev => ({ ...prev, ...itemsDeleted, ...createdItemsToDelete }));
    setDeletedLevels(prev => ({ ...prev, ...levelsDeleted, ...createdLevelsToDelete }));
    setCreatedItems(prev => ({ ...prev, ...createdItemsToDelete }));
    setCreatedLevels(prev => ({ ...prev, ...createdLevelsToDelete }));
  };

  const handleSelectItem = (record, selected, selectedRows) => {
    setSelectedItems(selectedRows);
    setSelectedRowKeys(selectedRows?.map(rowId));
  };

  const handleSelectAll = (selected, selectedRows) => {
    setSelectedItems(selectedRows);
    setSelectedRowKeys(selectedRows?.map(rowId));
  };

  const handleAddPurchaseItem = purchaseItem => {
    if (!purchaseItem) return;

    const cleanPrice = formatNumber(
      priceWithBdi && purchaseItem?.priceBdi ? purchaseItem.priceBdi : purchaseItem.price
    );
    const cleanQuantity = formatNumber(purchaseItem.quantity);

    const _id = uuidV4();

    const appropriations = purchaseItem?.parentList?.length
      ? purchaseItem?.parentList?.map(parent =>
          formatAppropriation({ appropriation: parent, idParent: _id, parent: purchaseItem })
        )
      : null;

    setList(prev => [
      ...prev,
      {
        ...purchaseItem,
        price: cleanPrice || 0,
        quantity: cleanQuantity || 0,
        id: _id,
        ...(!purchaseItem?.idItem && { idRefurbishItem: purchaseItem.id }),
        percentage: 1,
        [childrenColumnName]: appropriations,
        purchaseDate: new Date()
      }
    ]);
    setAddItemType(null);

    setCreatedItems(prev => ({ ...prev, [_id]: true }));
    if (!list.length) setReload(true);
  };
  const redirectToOrder = id => {
    history.push(`/profissional/gestao-de-compras/ordem-de-compras/visualizar/${id}`);
  };

  const _columns = useMemo(
    () =>
      purchaseColumns({
        readOnly,
        addAppropriation,
        editRow,
        deleteRow,
        levelsList,
        itemAppropriationMap,
        childrenColumnName,
        linkItem: ({ index }) =>
          setShowCatalogDrawer({ open: true, isLink: true, submit: i => linkRow({ index, libraryItem: i }) }),
        paymentView,
        limitedTable,
        redirectToOrder,
        isCreatePayment,
        isSupplierView,
        isMobile: _isMobile,
        priceWithBdi
      }),
    [levelsList, itemAppropriationMap, list, readOnly, paymentView, limitedTable]
  );

  const handleSubmitCatalog = ({ itemData, ...newItem }) => {
    if (newItem?.childId) {
      const index = list?.findIndex(i =>
        i?.idItem ? i?.idItem === newItem?.childId : i?.idRefurbishItem === newItem?.childId
      );

      if (index > -1) {
        setList(prev => {
          const _item = prev[index];

          const childIndex = _item?.[childrenColumnName]?.findIndex(i => i.refurbishItem?.id === newItem.id);

          if (childIndex > -1) {
            toast.error(`Apropriação já existe na ${typeMap[type].title}`);
            return prev;
          }

          const newList = [...prev];

          const newParentQuantity = formatNumber(_item.quantity + (newItem.quantity || 0));

          const newChildrenList = generateChildrenList(_item, childrenColumnName, newItem?.quantity);

          const newAppropriation = formatAppropriation({
            appropriation: newItem,
            idParent: _item?.id,
            parent: { ..._item, quantity: newParentQuantity }
          });

          setCreatedLevels(prevLevels => ({ ...prevLevels, [newAppropriation.id]: true }));

          newList[index] = {
            ..._item,
            quantity: newParentQuantity,
            [childrenColumnName]: newChildrenList ? [...newChildrenList, newAppropriation] : [newAppropriation]
          };
          return newList;
        });
        return;
      }

      handleAddPurchaseItem({ ...itemData, parentList: [newItem] });
      return;
    }
    const index = list?.findIndex(
      i => (newItem?.idItem && i?.idItem === newItem?.idItem) || (newItem?.id && i?.idRefurbishItem === newItem?.id)
    );

    if (index > -1) {
      toast.error(`Item já existe na ${typeMap[type].title}`);
      return;
    }
    handleAddPurchaseItem(newItem);
  };

  const tableComponent = (
    <Context
      data={{
        levelsList,
        itemAppropriationMap,
        addAppropriation,
        limitedTable,
        deleteRow,
        editRow,
        paymentView,
        parentColumnName,
        isSupplierView
      }}
    >
      <TableOrListV2
        id="purchaseTable"
        style={{ maxWidth: '100%' }}
        delayLoad={reload || refreshTable}
        columns={_columns}
        list={list}
        expandItem={expandItem}
        childrenColumnName={childrenColumnName}
        onSelect={handleSelectItem}
        onSelectAll={handleSelectAll}
        rowSelection={!readOnly && !paymentView}
        selectedRowKeys={selectedRowKeys}
        scroll={{ y: addItemType ? `calc(${height} - 75px)` : height, x: '100%' }}
        footer={
          addItemType
            ? () => (
                <AddPurchaseItemForm
                  idCompany={idCompany}
                  handleSubmit={handleSubmitCatalog}
                  paymentView={paymentView}
                  onClose={() => setAddItemType(null)}
                  type={addItemType}
                />
              )
            : null
        }
        $footerPadding={`${spaces.space1} ${spaces.space2}`}
        $footerWeight="unset"
        refreshColumns
        withCommonRow
        initialExpandedRowKeys={[]}
        customKey={rowId}
        $childRowColor={colors.neutral50}
        marginMobile={`${spaces.space0} 0`}
        readOnlyMobile={readOnly}
        noContentMobile={noContent}
        mobileItemFooter={PurchaseFooter}
        $paddingBottom={!readOnly ? '100px' : null}
      />
    </Context>
  );

  return (
    <>
      {renderNoContent ? (
        <NoContent
          description={noContent}
          icon={faSquareList}
          showButtonIcon={false}
          renderButton={false}
          styleIcon={{ color: colors.primary300 }}
          descriptionColor={colors.neutral500}
          style={{ width: '100%', padding: `${spaces.space3} 0 ${spaces.space3} 0` }}
        />
      ) : (
        tableComponent
      )}
      {invalidPaymentItems && <Error>{invalidPaymentItems}</Error>}

      {disableAddButtons ? (
        <Tooltip title="Para adicionar um item, é necessário selecionar um projeto">{AddItemsButtonsRow}</Tooltip>
      ) : (
        AddItemsButtonsRow
      )}

      {selectedItems?.length > 0 && (
        <PurchaseBulkActions
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          bulkEdit={bulkEditItems}
          bulkDelete={bulkDeleteItems}
        />
      )}
      {showCatalogDrawer && (
        <CatalogDrawer
          open
          onClose={() => setShowCatalogDrawer(false)}
          onSubmit={input =>
            showCatalogDrawer?.submit ? showCatalogDrawer?.submit(input) : handleSubmitCatalog(input)
          }
          idReference={idRefurbish}
          tabsToOpen={showCatalogDrawer?.isLink ? [0] : [-1, 0, 1]}
          subtitle={
            showCatalogDrawer?.isLink
              ? 'Busque o item da biblioteca para vincular com o item selecionado'
              : 'Busque pelo produto ou serviço que deseja adicionar'
          }
          fixedType={refurbishItemType.productLabor}
          itemsOnListMap={itemsOnListMap}
          priceWithBdi={priceWithBdi}
        />
      )}
    </>
  );
};

PurchaseTable.propTypes = {
  list: PropTypes.instanceOf(Array),
  setList: PropTypes.func,
  idRefurbish: PropTypes.number,
  parentColumnName: PropTypes.string,
  childrenColumnName: PropTypes.string,
  height: PropTypes.string,
  readOnly: PropTypes.bool,
  itemObjectsMap: PropTypes.instanceOf(Object),
  itemSettersMap: PropTypes.instanceOf(Object),
  paymentView: PropTypes.bool,
  limitedTable: PropTypes.bool,
  isCreatePayment: PropTypes.bool,
  isSupplierView: PropTypes.bool,
  type: PropTypes.string,
  priceWithBdi: PropTypes.bool,
  refreshTable: PropTypes.bool,
  invalidPaymentItems: PropTypes.string,
  setInvalidPaymentItems: PropTypes.func
};

export default PurchaseTable;
